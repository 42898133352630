import {Patrimoine} from '../patrimoine/patrimoine.page';
import {Rite} from './rite';

export class Marker implements Patrimoine {
//  public geopoint;

  constructor(public geopoint?: Geopoint, public categorie = {id: 0, libelle: ''},
              public rite?: Rite,
              public nom = '', public content = '',
              public dates: ListeDateMarker = null,
              public categorieIcon?: string,
              public id?: string) {
  }


}

export class ListeDateMarker {
  debutConstruction: {};
  finConstruction: {};

  constructor(debutConstr: Date = null, finConstr: Date = new Date(), list?: Array<DateMarker>) {
    this.debutConstruction = {libelle: 'Début de construction', debutConstr};
    this.finConstruction = {libelle: 'Fin de construction', finConstr};
  }

}

export class DateMarker {

  constructor(public libelle: string,
              public date: Date) {
  }

}

export interface AddMarkerOptions {
  draggable?: boolean;
  reset?: boolean;
  id?: any;
}

export interface Geopoint {

  lat: number;
  lng: number;

}
