import {Component, Input, OnInit} from '@angular/core';
import {LatLng} from 'leaflet';
import Parse from 'parse';
import {ModalController} from '@ionic/angular';
import {ListeDateMarker, Marker} from '../model/marker';
import {DataService, QueryResult} from '../services/data.service';
import {Rite} from '../model/rite';

@Component({
  selector: 'app-patrimoine-edit-marker',
  templateUrl: './patrimoine-edit-marker.page.html',
  styleUrls: ['./patrimoine-edit-marker.page.scss'],
})
export class PatrimoineEditMarkerPage implements OnInit {
  @Input() position: LatLng;
  pin: Marker;
  listeCategories: QueryResult[];
  listeRites: Rite[];

  constructor(private modalController: ModalController,
              private data: DataService) {
    this.pin = new Marker();
    this.pin.dates = new ListeDateMarker();
    data.getRiteList(true).then(result => {
      this.listeRites = result;
      console.log('listeRites : ', this.listeRites);
    });
  }

  ngOnInit() {
    this.loadCategories();
  }

  async loadCategories() {
    const table = Parse.Object.extend('patrimoine_categories');
    const query = new Parse.Query(table);
    const results = await query.find();

    this.listeCategories = results;
    //console.log('liste : ', results);
  }

  async savePoint() {
    this.pin.geopoint = {lat: this.position.lat, lng: this.position.lng};
    this.pin.dates = new ListeDateMarker();

   // console.log('enregistrement de ', this.pin);

    const patrimoine = Parse.Object.extend('patrimoine');
    // tslint:disable-next-line:variable-name
    const patrimoine_categories = Parse.Object.extend('patrimoine_categories');
    const rites = Parse.Object.extend('rites');

    const myPatrimoine = new patrimoine();
    // tslint:disable:variable-name
    const mypatrimoine_categories = patrimoine_categories.createWithoutData(this.pin.categorie);
    const mypatrimoine_rites = rites.createWithoutData(this.pin.rite.objectId);

    const point = new Parse.GeoPoint({latitude: this.pin.geopoint.lat, longitude: this.pin.geopoint.lng});
    myPatrimoine.set('geopoint', point);
    myPatrimoine.set('nom', this.pin.nom);
    myPatrimoine.set('dates', this.pin.dates);
    myPatrimoine.set('content', this.pin.content);
    myPatrimoine.set('categorie', mypatrimoine_categories);
    myPatrimoine.set('rite', mypatrimoine_rites);
    myPatrimoine.set('links', ['google.com']);

    const results = await myPatrimoine.save();

    //console.log('enregistré ', results);
    this.dismiss(true);
  }

  async dismiss(success: boolean) {
    await this.modalController.dismiss(success);
  }

}
